












































import {
  Component, Prop,
  Vue
} from 'vue-property-decorator';
import {
  Core
} from '@/store/core'
import {
  Auth
} from '@/store/auth'
import {
  User
} from '@/store/user'
import _ from 'lodash'
import {
  Web
} from '@/store/web'

import RawDataChart from './AgencyRawDataChart.vue'
@Component({
  components: {
    RawDataChart
  },
})
export default class Home extends Vue {


  @Prop({default:false})
  private debug:any;

  appSetting:any = {}
  chooseYear: any = '2563'
  chooseAgency:any = 85

  response:boolean = false
  private agency: any = null
  private years:any = []
  private xyeard =[{"year":"2563","open":true},{"year":"2564","open":true},{"year":"2565","open":true},{"year":"2566","open":true},{"year":"2567","open":true}]

  async created() {



   this.appSetting = await Core.getHttp(`/setting/app/`)

    this.appSetting = await Core.getHttp(`/setting/app/`)
    if(!this.debug){
      this.years = await _.filter(this.appSetting.year_result,{open:true})
    }else{
      this.years = this.appSetting.year_result
    }

    if(this.years[0]){
      this.chooseYear = this.years[this.years.length-1].year
    }



    this.agency = await Core.getHttp(`/api/ita/v1/agency/`)
    try {
      let user = await User.getProfile();
      if(user){
        this.chooseAgency = user.ext_link.agency
      }

    }
    catch(err) {

    }

    await this.loadData();

    this.response = true;
  }

  async loadData(){
   await Web.switchLoad(true)
    this.response = false;
   setTimeout(() => {  console.log("World!"); }, 5000);
   await Web.switchLoad(false)
    this.response = true;
  }





  prePrint(){
    window.print();
  }

}
