












import { Component, Vue, Watch,Prop } from "vue-property-decorator";

@Component({
  components: {

  },
  computed: {}
})

export default class Bars extends Vue {
  @Prop({default:'No Name'})
  name:any

  @Prop({default:'red'})
  color:any

  @Prop({default:'0'})
  score:any

  @Prop({default:''})
  sub:any
}
