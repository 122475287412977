






























import {
  Component,
  Vue,Prop
} from 'vue-property-decorator';
import {
  Core
} from '@/store/core'
import {
  Auth
} from '@/store/auth'
import {
  User
} from '@/store/user'
import {
  Web
} from '@/store/web'
import All from '@/components/ReportUp/All.vue'
import Agency from '@/components/ReportUp/Agency.vue'
import _ from 'lodash'
// import UpIIT from './UpIIT.vue'
// import UpEIT from './UpEIT.vue'
// import UpOIT from './UpOIT.vue'
import XLSX from 'xlsx'
@Component({
  components: {
    All,Agency //UpIIT,UpEIT,UpOIT,
  },
})
export default class Home extends Vue {

  @Prop({default:false})
  private debug:any;

  appSetting:any = {}
  year: any = '2567'
  response:boolean = false
  private years:any = []
 private xyeard = {"year":"2565","iit":false,"oit":true,"eit":false,"oit_link":false,"year_result":[{"year":"2563","open":true},{"year":"2564","open":true},{"year":"2565","open":true},{"year":"2566","open":true},{"year":"2567","open":true}]}
  async created() {
    this.debug = true
   
    this.appSetting = await Core.getHttp(`/setting/app/`)
    console.log('setting',this.appSetting);
    if(!this.debug){
      this.years =  await _.filter(this.appSetting.year_result,{open:true})
    }else{
      this.years = this.appSetting.year_result
    }

    if(this.years[0]){
      this.year = this.years[this.years.length-1].year
    }
    await this.loadData();

  }


  async loadData(){
    this.response = false
    await Web.switchLoad(true)
    this.response = true;
    await Web.switchLoad(false)
  }





  prePrint(){
    window.print();
  }




}
