






























































































































































































































































































































































































































































































































































































































































































import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

import { Web } from "@/store/web";
import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'
import _ from 'lodash'
import ExportOIT from './ExportOIT.vue'
@Component({
    components: {
        ExportOIT
    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    }
})
export default class Home extends Vue {
    @Prop({ default: 7 })
    agencyData: any;

    @Prop({ default: { oit: 0 } })
    all: any;

    @Prop({ default: '2565' })
    yearData: any;

    @Prop({ default: '2563' })
    lists: any;

    private year: any = []
    private rates: any = []
    private response: boolean = false
    private panel: any = [1, 0]
    private agency: any = {};
    private oitA: any = ''
    private oitB: any = ''
    isScore43:any = 0

    private data: any = [];

    private status: any = []

    public async created() {

        await this.run()

    }
    result: any = []
    appSetting: any = { oit_link: false }
    private async run() {
        await Web.switchLoad(true)
        this.appSetting = await Core.getHttp(`/setting/app/`)
        this.agency = await Core.getHttp(`/api/ita/v1/agency/${this.agencyData}/`)
        this.year = await Core.getHttp(`/api/ita/v1/year/`)
        this.year = await _.find(this.year, { year: this.yearData })
        if (this.year) {
            this.rates = await Core.getHttp(`/api/ita/v2/rate/${this.year.id}/`)
            this.result = await Core.getHttp(`/api/oit/v1/evaluateoit/?agency=${this.agencyData}&rate__year=${this.year.id}`)
            let data  = _.sumBy(this.result,(r:any)=>{return r.score}) 
            this.isScore43 = data
            this.data = await Core.getHttp(`/api/ita/v1/rateresult/?rate__year=${this.year.id}&agency=${this.agencyData}`)
            this.status = await Core.getHttp(`/api/ita/v1/ratestatus/`)
        
           
            this.oitA = await _.find(this.lists, { name: 'การเปิดเผยข้อมูล' })
            this.oitB = await _.find(this.lists, { name: 'การป้องกันการทุจริต' })
            await this.generateTable()
            await Web.switchLoad(false)
            this.response = true;
        }
        await Web.switchLoad(false)

    }

    private async generateTable() {
        for (let i = 0; i < this.rates.length; i++) {
            // console.log(this.rates[i].id);
            let result: any = _.filter(this.result, {
                'rate': this.rates[i].id
            })
            //this.rates[i].evaluate  = await this.getEvaluate(this.rates[i].id)

            let score = ((await _.sumBy(result, 'score')) / 1) * 100
            this.rates[i].score = score + "%"
            this.rates[i].score_raw = score
            this.rates[i].detail = _.filter(this.data, { rate: this.rates[i].id })

            this.rates[i].comment = (result[0]) ? result[0].comment : ''
            this.rates[i].status = (await _.find(this.status, { id: result[0].rate_status })).name
        }
    }

    private genScore() {

    }
    private openLink(url: string) {
        window.open(url, '_blank');
    }

}
