

















































































































































































import Navbar from "@/components/Core/Navbar.vue";
import Loading from "@/components/Web/Loading.vue";
import {
    Core
} from "@/store/core";
import {
    Web
} from "@/store/web";
import {
    Component,
    Vue,
    Watch,
    Prop
} from "vue-property-decorator";
import _ from 'lodash'
import XLSX from 'xlsx'
import Bars from '@/components/ReportUp/Bars.vue'
@Component({
    components: {
        Navbar,
        Loading,
        Bars
    },
    computed: {}
})

export default class TestDevClass extends Vue {
    @Prop({
        default: ''
    })
    year: any
    response: boolean = false;
    lists: any = []
    result: any = {
        AA: [],
        A: [],
        B: [],
        C: [],
        D: [],
        E: [],
        F: [],
        Other: [],

    }
    exports: any = []
    fullData: any = []
    headers: any = [{
            text: 'ลำดับ',
            value: 'index'
        },
        {
            text: 'คณะ/วิทยาลัย/ส่วนงานอื่น',
            value: 'agency.name'
        },
        {
            text: 'ผลคะแนน',
            value: 'all'
        },
        {
            text: 'ระดับ',
            value: 'rate'
        },
    ]
    search: String = ''
    async created() {
        await this.loadEnv();
    }

    async loadEnv() {
        let results = [
            'AA',
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'ผ่านดีเยี่ยม',
            'ผ่านดี',
            'ผ่าน',
            'ต้องปรับปรุง',
            'ต้องปรับปรุงโดยด่วน', 
        ]
        this.lists = await Core.getHttp(`/api/report/v1/reportall-all/?year=${this.year}`)
        await this.genAgency();
        this.result.AA = await _.orderBy(_.filter(this.lists, {
            rate: 'AA'
        }), (r: any) => {
            return -r.all
        })
        this.result.A = await _.orderBy(_.filter(this.lists, {
            rate: 'A'
        }), (r: any) => {
            return -r.all
        })
        this.result.B = await _.orderBy(_.filter(this.lists, {
            rate: 'B'
        }), (r: any) => {
            return -r.all
        })
        this.result.C = await _.orderBy(_.filter(this.lists, {
            rate: 'C'
        }), (r: any) => {
            return -r.all
        })
        this.result.D = await _.orderBy(_.filter(this.lists, {
            rate: 'D'
        }), (r: any) => {
            return -r.all
        })
        this.result.E = await _.orderBy(_.filter(this.lists, {
            rate: 'E'
        }), (r: any) => {
            return -r.all
        })
        this.result.F = await _.orderBy(_.filter(this.lists, {
            rate: 'F'
        }), (r: any) => {
            return -r.all
        })
        this.result.newA = await _.orderBy(_.filter(this.lists, {
            rate: 'ผ่านดีเยี่ยม'
        }), (r: any) => {
            return -r.all
        })
        this.result.newB = await _.orderBy(_.filter(this.lists, {
            rate: 'ผ่านดี'
        }), (r: any) => {
            return -r.all
        })
        this.result.newC = await _.orderBy(_.filter(this.lists, {
            rate: 'ผ่าน'
        }), (r: any) => {
            return -r.all
        })
        this.result.newD = await _.orderBy(_.filter(this.lists, {
            rate: 'ต้องปรับปรุง'
        }), (r: any) => {
            return -r.all
        })
        this.result.newE = await _.orderBy(_.filter(this.lists, {
            rate: 'ต้องปรับปรุงโดยด่วน'
        }), (r: any) => {
            return -r.all
        })

        this.result.Other = await _.orderBy(_.filter(this.lists, (r: any) => {
             return r.rate && !results.includes(r.rate)
        }), (r: any) => {
            return -r.all
        })

        // this.result.Other = await _.orderBy(_.filter(this.lists, (r: any) => {
        //     return (r.rate != 'AA' && r.rate != 'A' && r.rate != 'B' && r.rate != 'C' && r.rate != 'D' && r.rate != 'E' && r.rate != 'F')
        // }), (r: any) => {
        //     return -r.all
        // })


        this.fullData = await _.orderBy(this.lists, (r: any) => {
            return -r.all
        })
        let i =1
        this.fullData = await _.map(this.fullData,(r:any)=>{ 
          let obj = r
          obj.index = i++
          return obj;
        })
        let data = _.meanBy(this.fullData,(r:any)=>{return r.all})
       // alert(data);
        this.response = true;
    }

    async genAgency() {
        for (let i = 0; i < this.lists.length; i++) {
            this.exports.push({
                "หน่วยงาน": this.lists[i].agency.name,
                "คะแนน": this.lists[i].all,
                "ผล": this.lists[i].rate,
            })
        }
        // = await Core.getHttp(`/api/report/v1/reportall/?year=${this.year}`)
    }

    async onExport(data: any, name: any) {
        const dataWS = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb, name)
    }

}
