
















































































import Navbar from "@/components/Core/Navbar.vue";
import Loading from "@/components/Web/Loading.vue";
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Web } from "@/store/web";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import _ from 'lodash'
import XLSX from 'xlsx' // import xlsx
import {Result} from '@/store/result'
import {CoreResult} from '@/store/core_result'
import ExportEIT from './ExportEIT.vue'
@Component({
  components: {
    Navbar,
    Loading,
    ExportEIT
  },
  computed: {}
})

export default class TestDevClass extends Vue {
  @Prop({default:7})
  agencyData:any;

  @Prop({default:'2563'})
  yearData:any ;

  agency:any = null
  assignments:any = null


  year:any = null;
  issues:any = [];
  response:boolean  = false;
  score:any = ''
  score30:number = 0
  chooseAssignId:number = 1;
  allUser:number = 0;
  allAgency:number = 0;
  result:any = ''

  async getIssue(){
      let years =  await Core.getHttp(`/api/iit/v1/year`)
    let thisYear = _.find(years,{year:this.yearData}) 
    this.agency = await Core.getHttp(`/api/ita/v1/agency/${this.agencyData}/`)
    let raw = await Core.getHttp(`/api/report/v1/reportraweit/?agency=${this.agencyData}&year=${this.yearData}`)
    if(raw.length > 0){
      let data = raw[0]
      this.allUser = data.user_do
      this.allAgency = data.user_set
      this.assignments = _.filter(JSON.parse(data.rawType),{year:thisYear.id})
      this.year = data.year
      this.chooseAssignId = (this.assignments[0])?this.assignments[0].id:1
      this.issues  = JSON.parse(data.rawDone)
      this.score = data.score
      this.score30 = data.score30
      this.result = data.result
      this.response = true;
    }

    console.log(this.issues );
  }



  async created(){
    await Web.switchLoad(true)

    await this.getIssue();

    await Web.switchLoad(false)




  }









}
